export interface ProvinciaMetadata {
    Nombre: string,
    Subdomain: string,
    Titulo: string,
    TextoPersonalizado: string,
    Code: number,
    Correo: string,
}

let cmg: ProvinciaMetadata =
{
    Nombre: "Camagüey",
    Subdomain: "camaguey",
    Titulo: "Citas Camagüey",
    TextoPersonalizado: "Tierra de Agramonte, Gertrudis y Mireya Luis.",
    Code: 1,
    Correo: "camaguey@sicocinascomocaminas.com",
};

let pro: ProvinciaMetadata =
{
    Nombre: "Pinar del Río",
    Subdomain: "pinar",
    Titulo: "Citas Pinar",
    TextoPersonalizado: "Tierra de Tabaco, Mogotes y Linares.",
    Code: 2,
    Correo: "pinardelrio@sicocinascomocaminas.com",
};

let art: ProvinciaMetadata =
{
    Nombre: "Artemisa",
    Subdomain: "artemisa",
    Titulo: "Citas Artemisa",
    TextoPersonalizado: "Artemisa Nueva.",
    Code: 3,
    Correo: "artemisa@sicocinascomocaminas.com",
};

let hab: ProvinciaMetadata =
{
    Nombre: "Habana",
    Subdomain: "habana",
    Titulo: "Citas Habana",
    TextoPersonalizado: "Habana Linda.",
    Code: 4,
    Correo: "habana@sicocinascomocaminas.com",
};

let myb: ProvinciaMetadata =
{
    Nombre: "Mayabeque",
    Subdomain: "mayabeque",
    Titulo: "Citas Mayabeque",
    TextoPersonalizado: "Mayabeque",
    Code: 5,
    Correo: "mayabeque@sicocinascomocaminas.com",
};

let ijv: ProvinciaMetadata =
{
    Nombre: "Isla de la Juventud",
    Subdomain: "laisla",
    Titulo: "Citas La Isla",
    TextoPersonalizado: "Isla de playas, buceo y el Labra.",
    Code: 6,
    Correo: "isladelajuventud@sicocinascomocaminas.com",
};

let mtz: ProvinciaMetadata =
{
    Nombre: "Matanzas",
    Subdomain: "matanzas",
    Titulo: "Citas Matanzas",
    TextoPersonalizado: "Campeones! Campeones! Campeones!",
    Code: 7,
    Correo: "matanzas@sicocinascomocaminas.com",
};

let vc: ProvinciaMetadata =
{
    Nombre: "Villa Clara",
    Subdomain: "lasvillas",
    Titulo: "Citas Las Villas",
    TextoPersonalizado: "Tierra de Martha, Jova, Lara y Romañach.",
    Code: 8,
    Correo: "villaclara@sicocinascomocaminas.com",
};

let cfg: ProvinciaMetadata =
{
    Nombre: "Cienfuegos",
    Subdomain: "cienfuegos",
    Titulo: "Citas Cienfuegos",
    TextoPersonalizado: "Tierra del Benny, Celia, y Puig.",
    Code: 9,
    Correo: "cienfuegos@sicocinascomocaminas.com",
};

let ssp: ProvinciaMetadata =
{
    Nombre: "Sancti Spiritus",
    Subdomain: "ssp",
    Titulo: "Citas Sancti Spiritus",
    TextoPersonalizado: "La Tierra del Yayabo, Trinidad y Cepeda.",
    Code: 10,
    Correo: "sanctispiritus@sicocinascomocaminas.com",
};

let cav: ProvinciaMetadata =
{
    Nombre: "Ciego de Ávila",
    Subdomain: "ciego",
    Titulo: "Citas Ciego",
    TextoPersonalizado: "Tierra de Piña Colada, Cayos y Los Tigres.",
    Code: 11,
    Correo: "ciegodeavila@sicocinascomocaminas.com",
};

let ltu: ProvinciaMetadata =
{
    Nombre: "Las Tunas",
    Subdomain: "tunas",
    Titulo: "Citas Tunas",
    TextoPersonalizado: "Tierra del Cucalambé, Vicente y Stevenson.",
    Code: 12,
    Correo: "lastunas@sicocinascomocaminas.com",
};

let hlg: ProvinciaMetadata =
{
    Nombre: "Holguín",
    Subdomain: "holguin",
    Titulo: "Citas Holguín",
    TextoPersonalizado: "Tierra de Parques, Guardalavaca y Chapman.",
    Code: 13,
    Correo: "holguin@sicocinascomocaminas.com",
};

let grm: ProvinciaMetadata =
{
    Nombre: "Granma",
    Subdomain: "granma",
    Titulo: "Citas Granma",
    TextoPersonalizado: "Tierra de Céspedes, Bayamo y Despaigne,",
    Code: 14,
    Correo: "granma@sicocinascomocaminas.com",
};

let stg: ProvinciaMetadata =
{
    Nombre: "Santiago de Cuba",
    Subdomain: "santiago",
    Titulo: "Citas Santiago",
    TextoPersonalizado: "Tierra de Mariana, Maceo y Kindelán.",
    Code: 15,
    Correo: "santiago@sicocinascomocaminas.com",
};

let gtm: ProvinciaMetadata =
{
    Nombre: "Guantánamo",
    Subdomain: "guantanamo",
    Titulo: "Citas Guantánamo",
    TextoPersonalizado: "Tierra de María Caridad, Baracoa y Savón.",
    Code: 16,
    Correo: "guantanamo@sicocinascomocaminas.com",
};


export function GetProvinciaMetadaFromInt(provincia: number): ProvinciaMetadata {
    for (let index = 0; index < AllProvincias.length; index++) {
        const element = AllProvincias[index];
        if (element.Code === provincia) {
            return element;
        }
    }

    return cmg;
}

export const AllProvincias = [pro, art, hab, myb, ijv, mtz, vc, cfg, ssp, cav, cmg, ltu, hlg, grm, stg, gtm];



