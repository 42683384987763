import { Button, Slide, Typography, Backdrop, MenuItem, IconButton, Menu, Snackbar } from '@material-ui/core';
import { AndroidRounded, EmojiEmotions } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { AllProvincias, GetProvinciaMetadaFromInt, ProvinciaMetadata } from '../Utils/ProvinciasMetadata';
import { useWidth } from '../Utils/UseWidth';
import { useWindowSize } from '../Utils/UseWindowSize';
import logo from './../logo.svg';
import LocationCityRoundedIcon from '@material-ui/icons/LocationCityRounded';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Themer, { Palette } from '../Theme/Themer';


export function ScreenInicio(props: { provincia: number }) {
    const [provincia, setProvincia] = useState<ProvinciaMetadata>({
        Code: 1,
        Correo: "",
        Nombre: "",
        Subdomain: "",
        TextoPersonalizado: "",
        Titulo: "",
    });

    const [snackOpen, setSnackOpen] = useState(false);

    const windowSize = useWindowSize();

    const width = useWidth();

    const superShortWindow = windowSize.height < 500;

    const [menuProvinciasOpen, setMenuProvinciasOpen] = useState(false);

    const history = useHistory();

    const [latest, setLatestVersion] = useState<{ version: string, downloadURL: string }>({ version: "", downloadURL: "" });

    useEffect(() => {
        async function FetchLatest() {
            const temp: ProvinciaMetadata = GetProvinciaMetadaFromInt(props.provincia);
            setProvincia(temp);
            var response = await fetch("latest.json");
            var object = await response.json();
            setLatestVersion({
                version: object.version,
                downloadURL: object.urls[temp.Subdomain] || "",
            });
        };

        FetchLatest();
    }, [props.provincia])

    if (width === "xs" && windowSize.width > windowSize.height) { //landscape
        return (
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
                position: "fixed", left: 0, right: 0, top: 0, bottom: 0,
                padding: 20
            }}>
                <EmojiEmotions style={{ color: "#bbbbbb" }} />
                <Typography style={{ textAlign: "center" }}>Haga un mejor uso de nuestro sitio rotando su teléfono.</Typography>
            </div>
        );
    }

    return (
        <Slide direction="right" in mountOnEnter unmountOnExit>
            <div style={{}}>
                <div style={{ display: "flex", }}>
                    <div style={{ flex: 1, display: "flex", alignItems: "center", padding: 12, marginTop: 4 }}>
                        <img src={logo} alt="logo" width="48" height="48" />
                        <div style={{ display: "flex", flexDirection: "column", paddingLeft: 4, }}>
                            <Typography variant={width === "xs" ? "h4" : "h3"} color="secondary" style={{ marginLeft: 6, fontWeight: "bolder" }}>
                                {provincia.Titulo}
                            </Typography>

                        </div>
                    </div>
                </div>


                <div style={{
                    marginTop: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
                    position: superShortWindow ? "relative" : "fixed", left: superShortWindow ? undefined : 0, right: superShortWindow ? undefined : 0, top: superShortWindow ? undefined : 0, bottom: superShortWindow ? undefined : 80
                }}>

                    <Typography style={{ fontSize: 28, marginLeft: 6, color: "#eeeeee", textAlign: "center", marginTop: 20 }}>
                        Conoce Solteros
                    </Typography>
                    <Typography variant={width === "xs" ? "h4" : "h3"} style={{ color: "#eeeeee", fontWeight: "bolder", textAlign: "center", marginLeft: 10, marginRight: 10 }}>
                        Busca Espontaneidad
                    </Typography>
                    <div style={{ marginTop: 28, }}>
                        <Button href={latest.downloadURL} color="primary" variant="text" target="_blank"
                            style={{ fontSize: 20, textTransform: "none", fontWeight: "bolder", paddingLeft: 24, paddingRight: 24, paddingTop: 18, paddingBottom: 10, color: "#dddddd", backgroundColor: Palette.naranjaOscuro }}
                            onClick={() => { if (!latest.downloadURL) { setSnackOpen(true); } }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <AndroidRounded />
                                Descargar App
                            </div>
                        </Button>
                    </div>
                    <Typography style={{ fontSize: 9, color: "#cccccc", textAlign: "center", marginTop: 6, width: 300 }}>
                        Dispositivos Android.
                    </Typography>
                </div>

                <Backdrop open={true} style={{
                    zIndex: -10,
                    color: '#111111',
                }}>
                </Backdrop>

                <div style={{
                    position: "fixed", left: 0, right: 0, top: 0, bottom: 0,
                    backgroundImage: "url(\"Background.jpg\")",
                    backgroundRepeat: (width === "xs" || width === "sm" || width === "md") ? "no-repeat" : "round",
                    zIndex: -1000,
                }}>
                </div>


                <div style={{
                    display: "flex", justifyContent: "center",
                    padding: 6,
                    position: superShortWindow ? "relative" : "fixed", left: superShortWindow ? undefined : 0, right: superShortWindow ? undefined : 0, bottom: superShortWindow ? undefined : 0,
                }}>
                    <Typography style={{ fontSize: 9, color: "#cccccc" }}>Otra provincia?</Typography>
                </div>

                <div style={{
                    position: "fixed", right: -4, bottom: -4,
                }}>
                    <IconButton onClick={() => { setMenuProvinciasOpen(true) }} id="prov-button" style={{}}>
                        <LocationCityRoundedIcon style={{ color: "#ffffff", }} />
                    </IconButton>
                    <Menu
                        open={menuProvinciasOpen}
                        onClose={() => { setMenuProvinciasOpen(false); }}
                        anchorEl={document.getElementById("prov-button")}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                    >
                        {AllProvincias.map((provincia) => {
                            return <MenuItem value={provincia.Code} key={provincia.Code} onClick={() => { history.push("/" + provincia.Subdomain); setMenuProvinciasOpen(false); }}>
                                {provincia.Nombre}
                            </MenuItem>
                        })}
                    </Menu>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    ContentProps={{ style: { backgroundColor: "#cccccc", color: "#444444", } }}
                    open={snackOpen}
                    autoHideDuration={4000}
                    onClose={() => { setSnackOpen(false); }}
                    message={provincia.Code === 1 ? "Lanzamiento Octubre 15." : "Lanzamiento Noviembre 12."}
                    action={
                        <>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={e => { setSnackOpen(false); }}>
                                <CloseIcon fontSize="small" color="primary" />
                            </IconButton>
                        </>
                    }
                />
            </div >
        </Slide >
    );
}