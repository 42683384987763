import { CssBaseline } from '@material-ui/core';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ScreenInicio } from './ScreenInicio/ScreenInicio';
import AppThemer from './Theme/Themer';

function App() {
  return (
    <>
      <CssBaseline />
      <AppThemer>
        <Router>
          <Switch>
            <Route path="/camaguey">
              <ScreenInicio provincia={1}/>
            </Route>
            <Route path="/pinar">
              <ScreenInicio provincia={2} />
            </Route>
            <Route path="/artemisa">
              <ScreenInicio provincia={3} />
            </Route>
            <Route path="/habana">
              <ScreenInicio provincia={4} />
            </Route>
            <Route path="/mayabeque">
              <ScreenInicio provincia={5} />
            </Route>
            <Route path="/laisla">
              <ScreenInicio provincia={6} />
            </Route>
            <Route path="/matanzas">
              <ScreenInicio provincia={7} />
            </Route>
            <Route path="/lasvillas">
              <ScreenInicio provincia={8} />
            </Route>
            <Route path="/cienfuegos">
              <ScreenInicio provincia={9} />
            </Route>
            <Route path="/ssp">
              <ScreenInicio provincia={10} />
            </Route>
            <Route path="/ciego">
              <ScreenInicio provincia={11} />
            </Route>
            <Route path="/tunas">
              <ScreenInicio provincia={12} />
            </Route>
            <Route path="/holguin">
              <ScreenInicio provincia={13} />
            </Route>
            <Route path="/granma">
              <ScreenInicio provincia={14} />
            </Route>
            <Route path="/santiago">
              <ScreenInicio provincia={15} />
            </Route>
            <Route path="/guantanamo">
              <ScreenInicio provincia={16} />
            </Route>
            <Redirect
              to="/camaguey" />
          </Switch>
        </Router>
      </AppThemer>
    </>
  );
}

export default App;
