import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

export const Palette = {
  rosado: "#fc86b2",
  naranja: "#fe9a85",
  naranjaClaro: "#fcbbae",
  naranjaExtraClaro: "#ffe5e0",
  naranjaOscuro: "#e1735c",
  middle: "#fe5168",
  red: "#ec1c24",
}

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: Palette.naranja
    },
    secondary: {
      main: "#ffffff"
    },
    background: {
      default: "#ffffff"
    }
  }
});

export default function AppThemer(props: any) {
  return (
    <ThemeProvider theme={appTheme}>
      {props.children}
    </ThemeProvider >
  );
}



